//Variables
@landing-page-block-padding: 40px;


	//Custom Heading

	h4 {font-weight: 300;}
	h5, h6 {font-weight: 700;}

	// Image Background

	.image-bg {
		width: 100%;
		position: relative;

		background-attachment: fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		background-color: @brand-primary;
		background-repeat: no-repeat;

		.vertical-align {
			width: 100%;
			text-align: center;
		}

		h1, h2, h3, h4, h5, h6, p, label {color: #fff;}

		.mask {
			.rgba(black,0.7);
		}
	}

	// Blocks

		.landing-block {
			padding: @landing-page-block-padding 0 !important;
			background-color: @body-bg;
			&.white-bg {
				background-color: #fff;
			}
			&.gray-bg {
				background-color: @border-lighter;
			}
			&.bright-bg {
				background-color: @tone-bright;
			}

			// Pricing Table Mod
			.pricing-table-1-container {
				.pricing-box {border: 2px solid @border-lighter;}
				.pricing-box, .pricing-active {
					background-color: #fff;
					.pricing-head {
						.pricing-head-title {margin: 0 !important; border-radius: 1px 1px 0 0 !important;}
						h4 {
							border-bottom: 0 !important;
						}
					}
					.pricing-content {
						margin: 0;
						li {border-bottom: 0}
					}
					&:hover {
						li {color: @gray-dark;}
						.pricing-footer {color: @gray;}
					}
				}
			}
		}

	//Content Boxes
	@media (max-width: @screen-sm-max) {
		.content-box {margin-bottom: 20px;}
	}

	// Featured Image
	.featured-image {
		width: 831px;
		margin: 0 auto;
	}

	//Testimonials
	blockquote.testimonial {
		padding: 20px;
		font-size: 19px;
		border-left: 0;
		position: relative;
		.avatar {
			position: absolute;
			height: 50px;
			width: 50px;
		}
		.comment {
			margin-left: 70px;
			p {color: @gray;}
			small {font-style: normal;}
		}
	}


	// Header

	#header-unit {
		height: 700px;
		background-image: url(../demo/stockphoto/communication_04.jpg);
		.mask {
			position: absolute;
			top: 0; bottom: 0;
			left: 0;right: 0;
		}
	}

	//Contact Form
	#contact-form {
		background-image: url(../demo/stockphoto/communication_05.jpg);
		form .form-group:last-child {margin-bottom: 0;}

		.mask {
			margin: -@landing-page-block-padding 0;
			padding: @landing-page-block-padding 0;
		}

		.btn-sm.btn-social {
			padding: 5px 0;
			height: 28px;
			width: 28px;
			border-radius: 50%;
		}
	}